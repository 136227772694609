var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"font-semibold mb-2 pr-3 sm:pr-0"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.$attrs['required'] === undefined)?_c('span',{staticClass:"text-xs font-normal"},[_vm._v("(optioneel) "+_vm._s(_vm.$attrs["required"]))]):_vm._e()]),(_vm.$attrs['maxlength'])?_c('span',{staticClass:"text-xs text-brand-gray-400"},[_vm._v(_vm._s(_vm.value.length)+"/"+_vm._s(_vm.$attrs["maxlength"]))]):_vm._e()]):_vm._e(),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],class:[
      'border border-brand-purple-500 rounded w-full',
      'py-2 px-3',
      'form-input-default',
      'typography-input-text',
      _vm.isDisabled ? 'form-input-default-disabled' : '',
      _vm.hasError ? 'form-input-default-error' : ''
    ],domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}},'textarea',this.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }