<template>
  <div
    class="relative w-full"
    :class="[hasError ? 'form-group-error' : '']"
    v-bind="this.$attrs"
  >
    <div class="flex flex-row justify-between items-center">
      <n-form-label
        v-if="this.$attrs['label']"
        :label-for="this.$attrs['label-for']"
      >
        <n-html :text="this.$attrs['label']" />
      </n-form-label>

      <n-tooltip v-if="hasInformation">
        <div v-html="this.$attrs['info']"></div>
      </n-tooltip>
    </div>

    <slot />

    <div
      v-if="invalidFeedback !== '' && hasError"
      class="text-red-500 text-xs mt-0.5"
    >
      <n-html :text="invalidFeedback" />
    </div>
  </div>
</template>

<script>
export default {
  name: "components.form.group",
  data() {
    return {
      tooltipHover: false
    };
  },
  computed: {
    hasError() {
      // return this.$slots &&
      // this.$slots.default &&
      // this.$slots.default.length &&
      // this.$slots.default[0].data &&
      // this.$slots.default[0].data.attrs &&
      // "state" in this.$slots.default[0].data.attrs
      //   ? !this.$slots.default[0].data.attrs.state
      //   : false;
      return "state" in this.$attrs
        ? this.$attrs["state"] !== null
          ? !this.$attrs["state"]
          : false
        : false;
    },
    hasInformation() {
      return "info" in this.$attrs && this.$attrs["info"];
    },
    invalidFeedback() {
      return "invalid-feedback" in this.$attrs
        ? this.$attrs["invalid-feedback"]
        : "";
    },
    questionIconName() {
      return this.tooltipHover ? "QuestionHover" : "Question";
    }
  }
};
</script>
