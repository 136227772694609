export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET = (state, data) => {
  state.item = data;
};

export const RESET = state => {
  state.initialized = false;
  state.item = {};
};

export const SET_EMAIL_CHANGE = (state, data) => {
  state.email_change = data;
};
