<template>
  <b-button
    variant="link"
    class="button-regular-menu typography-button-menu"
    v-bind="this.$attrs"
  >
    <n-icon
      v-if="this.$attrs.icon"
      medium
      :name="this.$attrs.icon"
      style="margin-right:16px; margin-bottom: 3px;"
    ></n-icon>
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: "components.button.regular"
};
</script>
