import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import routes from "./routes";
import i18n from "../plugins/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit("application/CHECK");

  const authenticated = store.getters["application/isAuthenticated"];
  const initialized = store.getters["application/isInitialized"];

  document.title = `${i18n.t(`pages.${to.name}`)} - ${i18n.t("pages.suffix")}`;

  store.dispatch("application/get_portal_settings").then(() => {
    const portalSettings = store.getters["application/getPortalSettings"];

    if (portalSettings.maintenance) {
      const isMaintenance = to.matched.some(m => m.path === "/maintenance");
      if (!isMaintenance) {
        next({ name: "maintenance" });
      } else {
        next();
      }
    } else {
      if (to.matched.some(m => m.path === "/auth") && authenticated) {
        next({ name: "overview" });
      } else if (to.matched.some(m => m.meta.auth) && !authenticated) {
        next({ name: "auth.login" });
      } else if (to.matched.some(m => m.meta.auth) && !initialized) {
        next({ name: "init", params: { redirect: to.path, params: to.query } });
      } else {
        next();
      }
    }
  });
});

export default router;
