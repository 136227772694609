<template>
  <a :class="[isSmall ? 'typography-a-small' : 'typography-a-regular']">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "components.typography.a",
  computed: {
    isSmall() {
      return "small" in this.$attrs;
    }
  }
};
</script>
