<template>
  <div
    :class="[
      'flex w-full bg-white text-sm',
      classes,
      disabled ? 'border-brand-gray-300 bg-brand-gray-200' : '',
      border ? 'rounded border px-4 py-3.5 border-brand-gray-500 ' : ''
    ]"
  >
    <n-icon :name="icon" class="mr-2" v-if="icon" />
    <select
      :name="id"
      :id="id"
      @change="onchange"
      :class="[
        'outline-none font-serif w-full cursor-pointer',
        bold ? 'font-bold' : '',
        disabled ? 'border-brand-gray-300 bg-brand-gray-200' : ''
      ]"
      :disabled="disabled"
    >
      <option
        :value="option.value"
        :key="`dropdown-${option.key}`"
        v-for="option in options"
      >
        {{ option.key }}
      </option>
      <slot />
    </select>
  </div>
</template>

<script>
export default {
  name: "components.form.select",
  computed: {
    id() {
      return Math.random()
        .toString()
        .substring(2, 7);
    },
    bold() {
      return "bold" in this.$attrs;
    },
    border() {
      return "border" in this.$attrs;
    },
    disabled() {
      return "disabled" in this.$attrs;
    },
    options() {
      return "options" in this.$attrs ? this.$attrs.options : [];
    },
    classes() {
      return "class" in this.$attrs ? this.$attrs.class : "";
    },
    icon() {
      return "icon" in this.$attrs ? this.$attrs.icon : false;
    },
    selected() {
      return "selected" in this.$attrs ? this.$attrs.selected : null;
    },
    value() {
      return "value" in this.$attrs ? this.$attrs.value : null;
    }
  },
  methods: {
    onchange(e) {
      if (this.$attrs.onchange) this.$attrs.onchange(e);
    }
  }
};
</script>
