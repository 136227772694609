<template>
  <div class="info-balloon">
    <p class="title">
      <n-icon name="Info"></n-icon>
      <n-html :text="this.$attrs.title" />
    </p>
    <p class="detail">
      <n-html :text="this.$attrs.detail" />
    </p>
  </div>
</template>

<script>
export default {
  name: "components.layout.info-balloon",
  computed: {
    isSmall() {
      return "small" in this.$attrs;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../theme/variables";
.info-balloon {
  border: 1px solid $color-primary;
  border-radius: 3px;
  background: #f8f5f9;
  padding: 12px;

  p {
    font-family: "Noto Sans JP";
    font-size: 13px;
    color: $color-primary;
    line-height: 20px;
    margin-bottom: 12px !important;

    &.title {
      img {
        margin-right: 8px;
      }

      font-weight: bold;
    }
  }
}
</style>
