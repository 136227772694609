import Vue from "vue";

import heading from "../components/theme/typography/heading";
import paragraph from "../components/theme/typography/paragraph";
import h1 from "../components/theme/typography/h1";
import h2 from "../components/theme/typography/h2";
import h3 from "../components/theme/typography/h3";
import h4 from "../components/theme/typography/h4";
import p from "../components/theme/typography/p";
import a from "../components/theme/typography/a";
import card from "../components/theme/layout/card";
import infoBalloon from "../components/theme/layout/info-balloon";
import buttonRegular from "../components/theme/button/regular";
import buttonLink from "../components/theme/button/link";
import buttonCategory from "../components/theme/button/category";
import buttonMenu from "../components/theme/button/menu";
import formInputDefault from "../components/theme/form/input/default";
import formInputPassword from "../components/theme/form/input/password";
import formTextarea from "../components/theme/form/input/textarea";
import formGroup from "../components/theme/form/group";
import formLabel from "../components/theme/form/label";
import icon from "../components/theme/iconography/icon";
import selector from "../components/theme/form/selector";
import select from "../components/theme/form/select";
import seperator from "../components/theme/form/seperator";
import checkbox from "../components/theme/form/input/checkbox";
import tooltip from "../components/theme/tooltip";
import authPanel from "../components/auth/panel";
import table from "../components/theme/table/table";
import alert from "../components/theme/alert/alert";
import selectWithSearch from "@/components/theme/form/selectWithSearch";
import AsHtml from "../components/locale/as-html";

Vue.component("n-auth-panel", authPanel);
Vue.component("n-html", AsHtml);
Vue.component("n-heading", heading);
Vue.component("n-paragraph", paragraph);
Vue.component("n-h1", h1);
Vue.component("n-h2", h2);
Vue.component("n-h3", h3);
Vue.component("n-h4", h4);
Vue.component("n-p", p);
Vue.component("n-a", a);
Vue.component("n-card", card);
Vue.component("n-button-regular", buttonRegular);
Vue.component("n-button-link", buttonLink);
Vue.component("n-button-category", buttonCategory);
Vue.component("n-form-input-default", formInputDefault);
Vue.component("n-form-textarea", formTextarea);
Vue.component("n-form-input-password", formInputPassword);
Vue.component("n-form-group", formGroup);
Vue.component("n-form-label", formLabel);
Vue.component("n-tooltip", tooltip);
Vue.component("n-icon", icon);
Vue.component("n-selector", selector);
Vue.component("n-select", select);
Vue.component("n-select-search", selectWithSearch);
Vue.component("n-seperator", seperator);
Vue.component("n-button-menu", buttonMenu);
Vue.component("n-checkbox", checkbox);
Vue.component("n-info-balloon", infoBalloon);
Vue.component("n-table", table);
Vue.component("n-alert", alert);
