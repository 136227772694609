<template>
  <component
    :is="tag"
    class="text-sm leading-6"
    :class="[
      color,
      fontWeight,
      { 'md:text-3.5xl uppercase': size === 'h1' },
      { 'md:text-2.5xl uppercase': size === 'h2' }
    ]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      default: "h1"
    },
    color: {
      default: "text-black"
    },
    fontWeight: {
      default: "font-medium"
    },
    size: {
      default: "h1"
    }
  }
};
</script>
