import Vue from "vue";
import firebase from "firebase";

export const CHECK = state => {
  state.authenticated = !!localStorage.getItem("auth_token");
  if (state.authenticated) {
    Vue.$axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "auth_token"
    )}`;
  }
};

export const LOGIN = (state, token) => {
  localStorage.setItem("auth_token", token);
  state.authenticated = true;
  Vue.$axios.defaults.headers.Authorization = `Bearer ${token}`;
};

export const LOGOUT = state => {
  localStorage.removeItem("auth_token");
  firebase.auth().signOut();
  state.authenticated = false;
};

// export const LOGIN = (state, token) => {
//   localStorage.setItem("auth_token", token);
//   Vue.$axios.defaults.headers.common.Authorization = `Token ${token}`;
//   state.authenticated = true;
// };

// export const LOGOUT = state => {
//   localStorage.removeItem("auth_token");
//   Vue.$axios.defaults.headers.common.Authorization = "";
//   state.authenticated = false;
// };

export const SET_LOCALE = (state, data) => {
  state.locale = data;
};

export const SET_PORTAL_SETTINGS = (state, data) => {
  state.portal_settings = data;
};
