<template>
  <button
    :class="
      `
      flex text-sm font-serif
      ${variant === 'primary' && !isDisabled ? 'text-brand-purple-500' : ''}
      ${variant === 'cancel' && !isDisabled ? 'text-red-500' : ''}
      ${bold ? 'font-semibold' : 'underline'}
      ${isDisabled ? 'text-brand-gray-400 bg-opacity-40' : ''}
      `
    "
    v-bind="this.$attrs"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "components.button.link",
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    bold() {
      return "bold" in this.$attrs;
    },
    isDisabled() {
      return "disabled" in this.$attrs && this.$attrs.disabled === true;
    }
  }
};
</script>
