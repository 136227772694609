<template>
  <div>
    <div v-if="label" class="flex justify-between">
      <p class="font-semibold mb-2 pr-3 sm:pr-0">
        {{ label }}
        <span
          class="text-xs font-normal"
          v-if="$attrs['required'] === undefined"
          >(optioneel) {{ $attrs["required"] }}</span
        >
      </p>

      <span v-if="$attrs['maxlength']" class="text-xs text-brand-gray-400"
        >{{ value.length }}/{{ $attrs["maxlength"] }}</span
      >
    </div>
    <textarea
      :class="[
        'border border-brand-purple-500 rounded w-full',
        'py-2 px-3',
        'form-input-default',
        'typography-input-text',
        isDisabled ? 'form-input-default-disabled' : '',
        hasError ? 'form-input-default-error' : ''
      ]"
      v-model="filter"
      v-bind="this.$attrs"
    />
  </div>
</template>

<script>
import format from "../../../../mixins/format";

export default {
  name: "components.form.textarea.default",
  mixin: [format],
  data: () => ({
    date: {
      startDate: "",
      endDate: ""
    },
    filter: ""
  }),
  props: {
    value: [Number, String],
    label: String
  },
  computed: {
    isDisabled() {
      return "disabled" in this.$attrs && this.$attrs.disabled === true;
    },
    hasError() {
      return "state" in this.$attrs && !this.$attrs["state"];
    }
  },
  watch: {
    value() {
      this.filter = this.value;
    },
    filter() {
      this.$emit("input", this.filter);
    }
  },
  created() {
    console.log(this.$attrs["required"]);
    this.filter = this.value;
  }
};
</script>
