import Vue from "vue";

export const initialize = ({ commit, dispatch }) =>
  dispatch("getAll")
    .then(() => commit("INITIALIZED"))
    .catch(err => throw err);

export const getCategories = ({ commit }, params) =>
  Vue.$axiosContractService
    .get(
      `item-categories?template=${params.template}&contract_amount=${params.contract_amount}`
    )
    .then(result => commit("SET_CATEGORIES", result.data))
    .catch(() => Promise.reject());

export const getManufacturers = ({ commit }, params) =>
  Vue.$axiosContractService
    .get(
      `manufacturers?template=${params.template}&contract_amount=${params.contract_amount}&itemCategory=${params.item_category}&search=`
    )
    .then(result => commit("SET_MANUFACTURERS", result.data))
    .catch(() => Promise.reject());

export const getItems = ({ commit }, params) =>
  Vue.$axiosContractService
    .get(
      `items?template=${params.template}&contract_amount=${params.contract_amount}&itemCategory=${params.category}&manufacturer=${params.manufacturer}&search=`
    )
    .then(result => commit("SET_ITEMS", result.data))
    .catch(() => Promise.reject());

export const getTemplates = ({ commit }, params) =>
  Vue.$axiosContractService
    .get(
      `templates?template=${params.template}&contract_amount=${params.contract_amount}&item=${params.item}&retail_price=${params.retail_price}&search=`
    )
    .then(result => commit("SET_TEMPLATES", result.data))
    .catch(() => Promise.reject());

export const postContract = (x, payload) =>
  Vue.$axiosContractService
    .post("contracts", payload)
    .then(() => true)
    .catch(() => Promise.reject());
