<template>
  <component class="font-serif text-sm font-base" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      default: "p"
    }
  }
};
</script>
