<template>
  <label
    class="text-brand-gray-500 font-base text-sm flex flex-row items-start"
  >
    <input
      v-model="value"
      :class="[
        value ? 'checkbox-checked' : '',
        disabled ? 'opacity-50 bg-gray-200' : ''
      ]"
      :unchecked-value="unchecked_value"
      :value="checked_value"
      :disabled="disabled"
      class="text-sm rounded-px border w-5 h-5 form-checkbox text-brand-purple-500 focus:ring-brand-purple-500 focus:ring-opacity-50 placeholder:text-brand-gray-400 mr-4"
      name="checkbox"
      type="checkbox"
    />
    <n-html v-if="label" :text="label" />
    <slot v-else />
  </label>
</template>

<script>
export default {
  name: "components.form.input.checkbox",
  props: {
    value: Boolean
  },
  data() {
    return {};
  },
  computed: {
    label() {
      return "label" in this.$attrs ? this.$attrs.label : "";
    },
    checked_value() {
      return "checked_value" in this.$attrs ? this.$attrs.checked_value : true;
    },
    unchecked_value() {
      return "unchecked_value" in this.$attrs
        ? this.$attrs.unchecked_value
        : false;
    },
    disabled() {
      return "disabled" in this.$attrs ? this.$attrs.disabled : false;
    }
  },
  watch: {
    value(val) {
      this.$emit("input", val);
    }
  }
};
</script>
