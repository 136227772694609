<template>
  <span v-html="html"></span>
</template>

<script>
import DOMPurify from "dompurify";

const purifyConfig = {
  ALLOWED_TAGS: ["b", "q", "span", "strong", "br", "a", "u"],
  ALLOWED_ATTR: ["style", "href", "target"]
};

export default {
  props: {
    text: String
  },

  computed: {
    html: function() {
      return DOMPurify.sanitize(this.text, purifyConfig);
    }
  }
};
</script>
