<template>
  <v-select
    class="style-chooser font-normal"
    @input="oninput"
    @search="onSearch"
    :options="options"
    :placeholder="placeholder"
    :value="selectedLabel"
    :filterable="filterable"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <n-icon name="Chevron-down" black />
      </span>
    </template>
    <template #deselect="{ attributes }">
      <span v-bind="attributes">
        <n-icon name="Chevron-down" black />
      </span>
    </template>
    <div slot="no-options">
      <n-html :text="$t('form.dropdown.no_results')" />
    </div>
  </v-select>
</template>

<script>
export default {
  name: "components.form.select",
  props: {
    placeholder: String,
    filterable: Boolean
  },
  computed: {
    id() {
      return Math.random()
        .toString()
        .substring(2, 7);
    },
    bold() {
      return "bold" in this.$attrs;
    },
    border() {
      return "border" in this.$attrs;
    },
    disabled() {
      return "disabled" in this.$attrs;
    },
    options() {
      return "options" in this.$attrs ? this.$attrs.options : [];
    },
    classes() {
      return "class" in this.$attrs ? this.$attrs.class : "";
    },
    icon() {
      return "icon" in this.$attrs ? this.$attrs.icon : false;
    },
    value() {
      return "value" in this.$attrs;
    },
    selectedLabel() {
      if (!this.$attrs.value) {
        return "";
      }
      const option = this.options.find(o => o.code === this.$attrs.value);
      if (!option) {
        return "";
      }

      return option.label;
    }
  },
  methods: {
    oninput(e) {
      if (this.$attrs.oninput) this.$attrs.oninput(e.code);
    },
    onSearch(e) {
      if (this.$attrs.onsearch) this.$attrs.onsearch(e);
    }
  }
};
</script>

<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px #343a40 solid;
  padding: 14px 16px;
  line-height: normal;
}
.style-chooser .vs__search::placeholder {
  color: rgba(52, 58, 64, 0.8);
}
.style-chooser .vs__selected,
.style-chooser .vs__search {
  margin: 0;
  border: none;
}
.style-chooser .vs__search,
.style-chooser .vs__selected {
  padding: 0;
}
.style-chooser .vs__clear {
  display: none;
}
.style-chooser .vs__open-indicator {
  fill: #343a40;
}
</style>
