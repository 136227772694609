<template>
  <n-card no-margin>
    <div class="overflow-x-auto w-full" ref="outerTable">
      <table
        v-if="outerTableWidth !== 0"
        class="table-fixed overflow-x-scroll min-w-full"
      >
        <tr class="bg-brand-purple-100 bg-opacity-50 m-4 p-5 rounded-lg">
          <th
            v-for="(column, index) in columns"
            :class="
              `bg-brand-purple-100 bg-opacity-50 text-left text-brand-purple-500 font-serif font-semibold
                   ${index === 0 ? 'rounded-l-lg pl-4' : ''}
                   ${index === columns.length - 1 ? 'rounded-r-lg pr-4' : ''}
                   ${column.col_width ? column.col_width : ''}
                  `
            "
            v-bind:key="column.key"
          >
            <div class="flex cursor-pointer py-4" @click="toggleSort(column)">
              <n-html :text="column.title" />
              <div
                v-if="column.sortable"
                class="flex flex-col flex-shrink-0 pl-2 justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3 w-3"
                  :class="
                    sort === 'asc' && sortColumn === column.key
                      ? ''
                      : 'opacity-50'
                  "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  :stroke-width="
                    sort === 'asc' && sortColumn === column.key ? 5 : 2
                  "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3 w-3"
                  :class="
                    sort === 'desc' && sortColumn === column.key
                      ? ''
                      : 'opacity-50'
                  "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  :stroke-width="
                    sort === 'desc' && sortColumn === column.key ? 5 : 2
                  "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </th>
        </tr>
        <tr
          v-for="(row, index) in rowPagination"
          v-bind:key="index"
          @click="onRowClick(row)"
          class="border-b border-brand-gray-200"
        >
          <td :colspan="columns.length">
            <table class="table-fixed min-w-full" ref="innerTable">
              <tr>
                <td
                  v-for="(column, index) in columns"
                  :class="
                    `
                    font-serif text-left
                    ${index === 0 ? 'pl-4' : ''}
                    ${index === columns.length - 1 ? 'pr-4' : ''}
                    ${column.col_width ? column.col_width : ''}
                  `
                  "
                  v-bind:key="column.key"
                >
                  <div
                    v-if="column.link === true"
                    :class="
                      `
                      flex flex-row
                    `
                    "
                  >
                    <n-icon :name="column.linkIcon" class="mr-2" small />
                    <a
                      class="underline text-brand-purple-500 cursor-pointer"
                      @click="column.linkAction(row)"
                    >
                      <n-html :text="column.linkLabel" />
                    </a>
                  </div>
                  <div v-else :class="`flex flex-row py-4`">
                    <template v-if="column.formatValue === undefined">
                      <n-html :text="row[column.key]" />
                    </template>

                    <template v-else>
                      {{ column.formatValue(row[column.key]) }}
                    </template>
                  </div>
                </td>
              </tr>
              <tr v-if="row.has_extra_row">
                <td class="py-4 px-4" :colspan="columns.length">
                  <div v-html="row.extra_row" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-8" v-if="showPagination && numberOfPages > 1">
      <ul class="inline-flex">
        <li>
          <button
            class="h-10 px-3 mr-3 rounded border border-gray-200"
            @click="onPreviousPage"
          >
            <n-icon name="Chevron-left" small></n-icon>
          </button>
        </li>
        <li v-for="(page, index) in paginationItems" :key="'page-' + index">
          <button
            :class="
              `h-10 px-3 ${currentPage === page ? 'text-brand-purple-500' : ''}`
            "
            @click="onPageChange(page)"
          >
            {{ page }}
          </button>
        </li>
        <li>
          <button
            class="h-10 px-3 ml-3 rounded border border-gray-200"
            @click="onNextPage"
          >
            <n-icon name="Chevron-right" small></n-icon>
          </button>
        </li>
      </ul>
    </div>
  </n-card>
</template>

<script>
import _ from "lodash";
import format from "../../../mixins/format";

export default {
  name: "components.theme.table",
  mixins: [format],
  props: {
    columns: Array,
    rows: Array,
    rowClick: Function,
    initialSortColumn: {
      type: String
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    perPage: Number,
    maxPagesVisible: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      outerTableWidth: 0,
      innerTableWidth: 0,
      current: window.location.pathname,
      sort: "",
      sortColumn: "",
      currentPage: 1,
      currentIndex: 0,
      tableData: []
    };
  },
  created() {
    if (this.initialSortColumn) {
      this.sort = "desc";
      this.sortColumn = this.initialSortColumn;
    }

    this.tableData = _.orderBy(this.rows, this.sortColumn, this.sort);

    this.onPageChange(1);
  },
  mounted() {
    this.outerTableWidth = (
      this.$refs.outerTable.clientWidth / this.columns.length
    ).toFixed();
    this.innerTableWidth = this.outerTableWidth;

    this.$forceUpdate();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.rows.length / this.perPage);
    },
    rowPagination() {
      if (this.showPagination) {
        return this.tableData.slice(
          this.currentIndex,
          this.currentIndex + this.perPage
        );
      } else {
        return this.tableData;
      }
    },
    paginationItems() {
      const totalVisible = this.numberOfPages;

      if (totalVisible === 0) {
        return [];
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.numberOfPages,
        Math.max(0, this.maxPagesVisible) || this.numberOfPages,
        this.numberOfPages
      );

      if (this.numberOfPages <= maxLength) {
        return this.range(1, this.numberOfPages);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.numberOfPages - left + 1 + even;

      if (this.currentPage > left && this.currentPage < right) {
        const firstItem = 1;
        const lastItem = this.numberOfPages;
        const start = this.currentPage - left + 2;
        const end = this.currentPage + left - 2 - even;
        const secondItem = start - 1 === firstItem + 1 ? 2 : "...";
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : "...";

        return [
          1,
          secondItem,
          ...this.range(start, end),
          beforeLastItem,
          this.numberOfPages
        ];
      } else if (this.currentPage === left) {
        const end = this.currentPage + left - 1 - even;
        return [...this.range(1, end), "...", this.numberOfPages];
      } else if (this.currentPage === right) {
        const start = this.currentPage - left + 1;
        return [1, "...", ...this.range(start, this.numberOfPages)];
      } else {
        return [
          ...this.range(1, left),
          "...",
          ...this.range(right, this.numberOfPages)
        ];
      }
    }
  },
  methods: {
    toggleSort(column) {
      if (!column.sortable) {
        return;
      }

      this.sortColumn = column.key;

      if (this.sort === "asc") {
        this.sort = "desc";
      } else if (this.sort === "desc") {
        this.sort = "";
      } else {
        this.sort = "asc";
      }

      this.tableData = _.orderBy(this.rows, this.sortColumn, this.sort);
    },
    onRowClick(row) {
      if (this.rowClick) {
        this.rowClick(row);
      }
    },
    range(from, to) {
      const range = [];

      from = from > 0 ? from : 1;

      for (let i = from; i <= to; i++) {
        range.push(i);
      }

      return range;
    },
    onNextPage() {
      if (this.currentPage !== this.numberOfPages) {
        this.onPageChange(this.currentPage + 1);
      }
    },
    onPreviousPage() {
      if (this.currentPage !== 1) {
        this.onPageChange(this.currentPage - 1);
      }
    },
    onPageChange(value) {
      this.currentPage = value;
      this.currentIndex = value * this.perPage - this.perPage;
    }
  }
};
</script>
