import Vue from "vue";

/**
 * "Verify that the contract number is unique."
 *
 * The function is called from the `contract_number` field in the `contracts/create.vue` file
 * @param x - The current value of the field.
 * @param contract_number - The contract number to verify
 */
export const verify_contract_number = (x, contract_number) =>
  Vue.$axios
    .post("contracts/contract_number/verify", contract_number)
    .then(result => result.data)
    .catch(() => Promise.reject());

/**
 * "Initialize the store by getting all the data from the API and then committing the INITIALIZED mutation."
 *
 * The first thing we do is call the getAll action. This action will make a request to the API and return a promise. We
 * then chain a .then() to the promise. This will be called when the promise resolves. Inside the .then() we commit the
 * INITIALIZED mutation. This will set the initialized state to true
 */
export const initialize = ({ commit, dispatch }) =>
  dispatch("getAll")
    .then(() => commit("INITIALIZED"))
    .catch(err => throw err);

/**
 * "Get all contracts from the API and commit them to the store."
 *
 * The first line of the function is a comment. It's not required, but it's a good idea to include comments in your code
 */
export const getAll = ({ commit }) =>
  Vue.$axios
    .get("contracts/")
    .then(result => commit("SET_ALL", result.data))
    .catch(() => Promise.reject());

/**
 * "Get the contract with the given id and commit it to the store."
 *
 * The first line of the function is a comment. It's a good idea to add comments to your code. It helps you and others
 * understand what the code is doing
 * @param id - The id of the contract to fetch.
 */
export const get = ({ commit }, id) =>
  Vue.$axios
    .get(`contracts/${id}`)
    .then(result => commit("SET", result.data))
    .catch(() => Promise.reject());

/**
 * It takes in an id and a file_type, and returns a promise that resolves to the file
 * @param x - the Vuex store
 */
export const get_file = (x, { id, file_type }) =>
  Vue.$axios
    .get(`contracts/${id}/download/${file_type}`)
    .then(result => result)
    .catch((err) => Promise.reject(err));

export const cancel = (x, payload) =>
  Vue.$axios
    .post(`contracts/${payload.id}/cancel/`, payload.data)
    .then(result => Promise.resolve(result))
    .catch(result => Promise.reject(result));

/**
 * It takes a Vuex store, an id, and a payload, and then it makes a POST request to the server with the id and payload
 * @param x - the Vuex store
 * @param id
 * @param payload
 */
export const change = (x, { id, payload }) =>
  Vue.$axios
    .post(`contracts/${id}/change/`, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(result => Promise.resolve(result))
    .catch(result => Promise.reject(result));

export const getContractClosingCodes = ({ commit }, payload) =>
  Vue.$axios
    .get(`contracts/${payload.contract_id}/close_codes/`)
    .then(result => commit("SET_CLOSING_CODES", result.data))
    .catch(() => Promise.reject());

export const getContractCloseDate = ({ commit }, payload) =>
  Vue.$axios
    .post(`contracts/${payload.contract_id}/close_date/${payload.close_code}`)
    .then(result => commit("SET_CLOSING_DATE", result.data.value))
    .catch(result => Promise.reject(result));

/**
 * It takes a Vuex store and a payload, and then it makes a POST request to the server with the payload
 * @param id
 * @param payload
 */
export const requestContractDiscountToStay = (id, payload) =>
  Vue.$axios
    .post(
      `contracts/${payload.data.contract_id}/request_contract_discount/`,
      payload
    )
    .then(result => Promise.resolve(result))
    .catch(result => Promise.reject(result));

export const getContractClosingReasons = ({ commit }, close_code) =>
  Vue.$axios
    .get(`contracts/close_reason/${close_code}`)
    .then(result => commit("SET_CLOSING_REASONS", result.data.value))
    .catch(result => Promise.reject(result));

/**
 * It takes in a contract id and a payload, and then it sends a POST request to the server with the contract id and payload
 * @param x - the state
 * @param id
 * @param payload
 */
export const addProduct = (x, { id, payload }) => {
  Vue.$axios
    .post(`contracts/${id}/add_product/`, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(result => Promise.resolve(result))
    .catch(result => Promise.reject(result));
};
