var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full"},[(_vm.type === 'date')?_c('date-range-picker',_vm._b({staticClass:"border rounded border-black w-full ",attrs:{"singleDatePicker":true,"ranges":false,"minDate":_vm.minDate,"maxDate":_vm.maxDate,"opens":"right","locale-data":_vm.localeData,"control-container-class":"border-0 w-full px-4 py-3.5"},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('span',{},[_vm._v(" "+_vm._s(picker.startDate ? _vm.format_date(picker.startDate, "DD-MM-YYYY") : _vm.$t("form.report_date.placeholder"))+" ")])]}}],null,false,746280316),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'date-range-picker',this.$attrs,false)):(_vm.type === 'textarea')?_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],class:[
      'border border-brand-gray-500 rounded w-full',
      'py-2 px-3',
      'form-input-default',
      'typography-input-text',
      _vm.isDisabled ? 'form-input-default-disabled' : '',
      _vm.hasError ? 'form-input-default-error' : ''
    ],domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}},'textarea',this.$attrs,false)):(_vm.type === 'file')?_c('input',{attrs:{"type":"file","accept":"image/jpeg, image/png, image/jpg","placeholder":_vm.$t('form.file.placeholder')}}):(((this.$attrs).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"font-serif w-full rounded border px-4 py-3.5 form-input text-brand-gray-500 focus:ring-brand-purple-500 focus:ring-opacity-50 placeholder:text-brand-gray-400 text-base",class:[
      _vm.isDisabled ? 'disabled bg-brand-gray-200' : '',
      _vm.hasError ? 'border-brand-red-500' : ' border-brand-gray-500'
    ],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.filter)?_vm._i(_vm.filter,null)>-1:(_vm.filter)},on:{"change":function($event){var $$a=_vm.filter,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.filter=$$a.concat([$$v]))}else{$$i>-1&&(_vm.filter=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.filter=$$c}}}},'input',this.$attrs,false)):(((this.$attrs).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"font-serif w-full rounded border px-4 py-3.5 form-input text-brand-gray-500 focus:ring-brand-purple-500 focus:ring-opacity-50 placeholder:text-brand-gray-400 text-base",class:[
      _vm.isDisabled ? 'disabled bg-brand-gray-200' : '',
      _vm.hasError ? 'border-brand-red-500' : ' border-brand-gray-500'
    ],attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.filter,null)},on:{"change":function($event){_vm.filter=null}}},'input',this.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"font-serif w-full rounded border px-4 py-3.5 form-input text-brand-gray-500 focus:ring-brand-purple-500 focus:ring-opacity-50 placeholder:text-brand-gray-400 text-base",class:[
      _vm.isDisabled ? 'disabled bg-brand-gray-200' : '',
      _vm.hasError ? 'border-brand-red-500' : ' border-brand-gray-500'
    ],attrs:{"type":(this.$attrs).type},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}},'input',this.$attrs,false)),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }