<template>
  <div
    :class="[
      'relative flex flex-col items-center border border-brand-purple-500 cursor-pointer rounded px-5 py-4',
      selected ? 'bg-brand-purple-100/25' : '',
      hasIcon ? 'form-selector-large-default' : 'form-selector-small-default'
    ]"
  >
    <n-icon
      xl
      v-if="this.$attrs.icon"
      auto
      :name="this.$attrs.icon"
      class="mb-3"
    />
    <p class="text-center">{{ this.$attrs.text }}</p>
    <n-icon v-if="selected" small name="Check" class="absolute top-2 right-2" />
  </div>
</template>

<script>
export default {
  name: "components.form.selector",
  props: {
    selected: Boolean
  },
  computed: {
    hasIcon() {
      return "icon" in this.$attrs;
    }
  }
};
</script>
