<template>
  <h1 :class="['typography-h1']">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: "components.typography.h1"
};
</script>
