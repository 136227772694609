var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._b({class:[
    'text-sm font-semibold py-2 px-4 rounded-3xl transition transform hover:-translate-y-0.5',
    _vm.isSecondary
      ? 'bg-brand-purple-100 bg-opacity-50 text-brand-purple-500 border border-brand-purple-500'
      : 'bg-brand-yellow-500 text-brand-gray-500',
    _vm.isCancel
      ? 'bg-brand-red-100 bg-opacity-50 text-brand-red-500 border border-brand-red-500'
      : '',
    _vm.isDisabled ? 'bg-brand-gray-300 text-brand-gray-400 bg-opacity-40' : '',
    _vm.isFullWidth ? 'w-full' : ''
  ],attrs:{"disabled":_vm.isDisabled}},'button',this.$attrs,false),[_vm._t("default"),(this.$attrs.icon)?_c('n-icon',{staticStyle:{"margin-left":"12px","margin-bottom":"3px"},attrs:{"name":this.$attrs.icon,"white":!_vm.isSecondary}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }