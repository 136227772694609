import router from "../../router";
import Vue from "vue";
import i18n from "../../plugins/i18n";

export const login = ({ commit }, token) => {
  commit("LOGIN", token);
  console.log("login");
  router.push({ name: "overview" }).catch(() => {});
};

export const logout = ({ commit, dispatch }) => {
  commit("LOGOUT");
  dispatch("terminate");
  router.push({ name: "auth.login" });
};

export const initialize = ({ dispatch }) =>
  Promise.all([
    dispatch("customer/initialize", {}, { root: true }),
    dispatch("user/initialize", {}, { root: true }),
    dispatch("contracts/initialize", {}, { root: true }),
    dispatch("claims/initialize", {}, { root: true }),
    dispatch("payments/initialize", {}, { root: true })
  ]).catch(() => {
    return Promise.reject("init error");
  });

export const terminate = ({ commit, dispatch }) => {
  commit("customer/RESET", {}, { root: true });
  commit("user/RESET", {}, { root: true });
  commit("contracts/RESET", {}, { root: true });
  commit("claims/RESET", {}, { root: true });
  commit("payments/RESET", {}, { root: true });
  dispatch("setLocale");
};

export const setLocale = ({ commit, getters }, locale) => {
  if (locale) {
    commit("SET_LOCALE", locale);
  }
  i18n.locale = getters["getLocale"];
};

export const userExists = ({ getters }, email) => {
  return Vue.$axios.post("/user/exists", {
    email: email,
    locale: getters["getLocale"]
  });
};

export const get_portal_settings = ({ commit }) => {
  return Vue.$axios
    .get("user/portal_settings")
    .then(result => {
      commit("SET_PORTAL_SETTINGS", result.data);
    })
    .catch(() => Promise.reject());
};

export const uploadImageForSupportRequest = (x, { payload }) =>
  Vue.$axios
    .post(`user/support/upload`, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(result => Promise.resolve(result))
    .catch(result => Promise.reject(result));
