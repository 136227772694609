<template>
  <img
    class="n-icon"
    :class="{ 'n-icon-center': center }"
    :src="icon"
    :style="
      auto + xs + small + medium + large + xl + xxl + xxxl + white + black
    "
    alt="Icon"
  />
</template>

<script>
export default {
  name: "components.iconography.icon",
  data: () => ({
    auto: "",
    width: {
      xs: 12,
      small: 16,
      medium: 24,
      large: 32,
      xl: 48,
      xxl: 56,
      xxxl: 68
    }
  }),
  computed: {
    icon() {
      let name = this.$attrs.name.toLowerCase();
      name = name.replaceAll(" ", "-");
      return `/lowercase_icons/${name}.svg`;
    },
    xs() {
      return "xs" in this.$attrs
        ? `max-width:${this.width.xs}px; min-width:${this.width.xs}px;`
        : "";
    },
    small() {
      return "small" in this.$attrs
        ? `max-width:${this.width.small}px; min-width:${this.width.small}px;`
        : "";
    },
    medium() {
      return "medium" in this.$attrs
        ? `max-width:${this.width.medium}px; min-width:${this.width.medium}px;`
        : "";
    },
    large() {
      return "large" in this.$attrs
        ? `max-width:${this.width.large}px; min-width:${this.width.large}px;`
        : "";
    },
    xl() {
      return "xl" in this.$attrs
        ? `max-width:${this.width.xl}px; min-width:${this.width.xl}px;`
        : "";
    },
    xxl() {
      return "xxl" in this.$attrs
        ? `max-width:${this.width.xxl}px; min-width:${this.width.xxl}px;`
        : "";
    },
    xxxl() {
      return "xxxl" in this.$attrs
        ? `max-width:${this.width.xxxl}px; min-width:${this.width.xxxl}px;`
        : "";
    },
    white() {
      if ("white" in this.$attrs && this.$attrs.white) {
        return "filter: brightness(0) invert(1);";
      }
      return "";
    },
    black() {
      if ("black" in this.$attrs) {
        return "filter: brightness(0);";
      }
      return "";
    },
    center() {
      return "center" in this.$attrs;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if ("auto" in this.$attrs) {
        let style = `width:${this.width.small}px;`;
        let width =
          this.$el.parentElement.clientWidth -
          window
            .getComputedStyle(this.$el.parentElement)
            .paddingLeft.match(/-?\d+\.?\d*/)[0] -
          window
            .getComputedStyle(this.$el.parentElement)
            .paddingRight.match(/-?\d+\.?\d*/)[0];
        Object.values(this.width).forEach(val => {
          if (val > width) {
            this.auto = style;
          }
          style = `width:${val}px;`;
        });
        if (!this.auto) {
          this.auto = style;
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "../../../theme/variables";

.n-icon-center {
  display: block;
  margin: auto;
}

.n-icon.color-on-hover {
  fill: $color-base-40;

  &:hover {
    fill: $color-primary;
  }
}
</style>
