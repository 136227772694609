import i18n from "../../plugins/i18n";

export const isAuthenticated = state => state.authenticated;

export const isInitialized = (state, getters, rootState, rootGetters) => {
  return (
    rootGetters["customer/isInitialized"] &&
    rootGetters["user/isInitialized"] &&
    rootGetters["contracts/isInitialized"] &&
    rootGetters["claims/isInitialized"]
  );
};

export const getLocale = (state, getters, rootState, rootGetters) => {
  if (state.locale) {
    return state.locale;
  }

  const customer_locale = rootGetters["customer/getLocale"];
  if (customer_locale) {
    return customer_locale;
  }

  return i18n.browserLocale;
};

export const getPortalSettings = state => state.portal_settings;
