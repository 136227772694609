import Vue from "vue";

export const initialize = ({ commit, dispatch }) =>
  dispatch("getAll")
    .then(() => commit("INITIALIZED"))
    .catch(err => {
      throw err;
    });

export const getAll = ({ commit }) =>
  Vue.$axios
    .get("claims/")
    .then(result => commit("SET_ALL", result.data))
    .catch(() => Promise.reject());

export const get = ({ commit }, id) =>
  Vue.$axios
    .get(`claims/${id}`)
    .then(result => commit("SETNEWCLAIM", result.data))
    .catch(() => Promise.reject());

export const create = ({ commit }, payload) =>
  Vue.$axios
    .post(`claims/`, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(result => {
      commit("SETNEWCLAIM", result.data);
      return result;
    })
    .catch(() => Promise.reject());

export const checkClaimStatus = ({ commit, state }) => {
  Vue.$axios
    .get(`claims/${state.claim.id}`)
    .then(result => commit("SETNEWCLAIM", result.data))
    .catch(() => Promise.reject());
};

export const updateClaimStore = ({ commit }, payload) => {
  return Vue.$axios
    .post(`claims/claim-route/update-store`, payload)
    .then(result => commit("SETNEWCLAIM", result.data))
    .catch(() => Promise.reject());
};

export const getStore = ({ commit }, payload) => {
  return Vue.$axiosRoutingRules
    .get(`supplier/filter?number=${payload}`)
    .then(result => {
      commit("SETSTORE", result.data);
    })
    .catch(() => Promise.reject());
};

export const getStores = ({ commit }, { template, zipcode, city }) => {
  let url = `supplier/all/filter?saleschannel=${template}`;
  if (zipcode) {
    url = `supplier/all/filter?saleschannel=${template}&zipcode=${zipcode}`;
  }

  if (city) {
    url = `supplier/all/filter?saleschannel=${template}&city=${city}`;
  }

  return Vue.$axiosRoutingRules
    .get(url)
    .then(result => {
      commit("SETSTORES", result.data);
    })
    .catch(() => Promise.reject());
};

export const getAllTemplates = ({ commit }) => {
  return Vue.$axios
    .get(`claims/templates`)
    .then(result => {
      commit("GETTEMPLATES", result.data);
    })
    .catch(() => Promise.reject());
};

export const updateTheftReport = (x, { id, payload }) => {
  return Vue.$axios
    .post(`claims/${id}/official_report`, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(result => result)
    .catch(() => Promise.reject());
};
