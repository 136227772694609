<template>
  <b-button
    variant="link"
    class="button-regular-category typography-button-category"
    v-bind="this.$attrs"
  >
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: "components.button.category"
};
</script>
