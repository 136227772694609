export default {
  initialized: false,
  items: [],
  item: {},
  store: {},
  stores: [],
  claim: {},
  templates: []
  // claim: {
  //   id: "c5378c1b-f8e5-452b-9379-b8389da6fce5",
  //   navision_ext_id: "SH026119",
  //   contract_id: "7058434a-6b20-4571-93f6-ad56d568a77b",
  //   damage_cause: "TECHNISCH DEFECT",
  //   damage_date: "2020-12-02",
  //   damage_description: "Informatie",
  //   problem_area: "AUDIO PROBLEEM",
  //   status: "On Hold",
  //   on_hold_code: "",
  //   repairer: "L00098",
  //   routing: "0",
  //   store_no: "L00098",
  //   under_warranty: false,
  //   wrong_repair: false,
  //   customer_notification: "Customer notification voor claim",
  //   is_routed: true,
  //   location_in_template: false
  // }
};
