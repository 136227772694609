var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'flex w-full bg-white text-sm',
    _vm.classes,
    _vm.disabled ? 'border-brand-gray-300 bg-brand-gray-200' : '',
    _vm.border ? 'rounded border px-4 py-3.5 border-brand-gray-500 ' : ''
  ]},[(_vm.icon)?_c('n-icon',{staticClass:"mr-2",attrs:{"name":_vm.icon}}):_vm._e(),_c('select',{class:[
      'outline-none font-serif w-full cursor-pointer',
      _vm.bold ? 'font-bold' : '',
      _vm.disabled ? 'border-brand-gray-300 bg-brand-gray-200' : ''
    ],attrs:{"name":_vm.id,"id":_vm.id,"disabled":_vm.disabled},on:{"change":_vm.onchange}},[_vm._l((_vm.options),function(option){return _c('option',{key:("dropdown-" + (option.key)),domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.key)+" ")])}),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }