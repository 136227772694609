import Vue from "vue";

const config = {
  baseUrl:
    process.env.VUE_APP_POSTAL_CODE_SERVICE_API_URL || "http://localhost:9000"
};

export const verify_relation_number = (x, relation_number) =>
  Vue.$axios
    .post("customer/relation_number/verify", relation_number)
    .then(result => result.data)
    .catch(() => Promise.reject());

export const get_address = (_, payload) => {
  return Vue.$axios
    .post(`${config.baseUrl}SearchAddress`, payload)
    .then(result => result.data)
    .catch(err => throw err);
};

export const initialize = ({ commit, dispatch }) =>
  Promise.all([dispatch("get"), dispatch("get_mutation")])
    .then(() => commit("INITIALIZED"))
    .catch(err => throw err);

export const get = ({ commit }) =>
  Vue.$axios
    .get("customer/me")
    .then(result => commit("SET", result.data))
    .catch(() => Promise.reject());

export const get_mutation = ({ commit }) =>
  Vue.$axios
    .get("customer/me/mutation")
    .then(result => commit("SET_MUTATION", result.data))
    .catch(e => {
      if (e.response.status === 404) {
        commit("SET_MUTATION", {});
      } else {
        Promise.reject();
      }
    });

export const save = (x, payload) =>
  Vue.$axios.post(`customer/${payload.id}`, payload).catch(e => {
    throw e.response.data["error"];
  });

export const get_ibans = ({ commit }) =>
  Vue.$axios
    .get("customer/ibans")
    .then(result => commit("SET_IBANS", result.data))
    .catch(() => Promise.reject());

export const save_iban = (x, payload) =>
  Vue.$axios.post("customer/ibans", payload).catch(e => {
    throw e.response.data["error"];
  });

export const save_communication = (x, payload) =>
  Vue.$axios
    .post(`customer/${payload.id}/communication`, payload.data)
    .then()
    .catch(() => Promise.reject());
