<template>
  <div
    :class="
      `bg-white
      ${useBorder ? 'border' : 'shadow-lg'}
      ${noMargin ? 'm-0' : 'm-4'}
      ${noPadding ? 'p-0' : 'p-6'}
      rounded-lg`
    "
    v-bind="this.$attrs"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "components.layout.card",
  props: {
    useBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    noPadding() {
      return "no-padding" in this.$attrs;
    },
    noMargin() {
      return "no-margin" in this.$attrs;
    }
  }
};
</script>
