<template>
  <h3 :class="['typography-h3']">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: "components.typography.h3"
};
</script>
