export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET_ALL = (state, data) => {
  state.items = data;
};

export const SET = (state, data) => {
  state.item = data;
};

export const SET_CLOSING_REASONS = (state, data) => {
  state.closing_reasons = data;
};

export const SET_CLOSING_CODES = (state, data) => {
  state.closing_codes = data;
};

export const SET_CLOSING_DATE = (state, data) => {
  state.closing_date = data;
};

export const RESET = state => {
  state.initialized = false;
  state.items = [];
  state.item = {};
};
