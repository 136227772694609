export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET_ALL = (state, data) => {
  state.items = data;
};

export const SET = (state, data) => {
  state.item = data;
};

export const RESET = state => {
  state.initialized = false;
  state.items = [];
  state.item = {};
};

export const SETNEWCLAIM = (state, data) => {
  state.claim = data;
};

export const SETSTORE = (state, data) => {
  state.store = data;
};

export const SETSTORES = (state, data) => {
  state.stores = data;
};

export const GETTEMPLATES = (state, data) => {
  state.templates = data;
};
