import Vue from "vue";
import moment from "moment";

export default Vue.mixin({
  methods: {
    format_date(str, format) {
      return moment(str).format(format);
    }
  }
});
