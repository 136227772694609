import Vue from "vue";

export const initialize = ({ commit, dispatch }) =>
  dispatch("getAll")
    .then(() => commit("INITIALIZED"))
    .catch(err => throw err);

export const getAll = ({ commit }) =>
  Vue.$axios
    .get("payments/")
    .then(result => commit("SET_ALL", result.data))
    .catch(() => Promise.reject());

export const getAllPaymentLinks = () =>
  Vue.$axios
    .get("payments/payment_links")
    .then(result => Promise.resolve(result))
    .catch(() => Promise.reject());

export const get_file = (x, { document_no }) =>
  Vue.$axios
    .get(`payments/${document_no}/download`)
    .then(result => result)
    .catch(() => Promise.reject());
