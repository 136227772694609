import Vue from "vue";
import VueGtag from "vue-gtag";

import "./firebase";
import "./plugins/components";
import "./plugins/axios";
import "./assets/css/index.css";

import App from "./App.vue";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM_TAG || "" }
});

const urlParams = new URLSearchParams(window.location.search);
const locale = urlParams.get("locale");

if (locale) {
  store.dispatch("application/setLocale", locale);
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
