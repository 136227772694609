import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const messages = {};
  var request = new XMLHttpRequest();
  // Access translation files from the API instead of retreiving them locally
  request.open(
    "GET",
    process.env.VUE_APP_API_URL + "translations/?type=CUSTOMER",
    false
  );
  request.send();

  // Begin accessing JSON data here
  var languages = JSON.parse(request.responseText);
  languages.forEach(data => {
    messages[data.language] = data.data;
  });

  Object.keys(messages).forEach(locale => {
    const matched = locale.match(/[a-z]+/);
    if (matched && matched.length > 0) {
      const new_locale = matched[0];
      let country = matched[0].toUpperCase();
      if (country === "EN") {
        country = "US";
      }
      if (
        Object.keys(messages).includes(`${new_locale}_${country}`) &&
        !Object.keys(messages).includes(new_locale)
      ) {
        messages[new_locale] = messages[`${new_locale}_${country}`];
      }
    }
  });

  Object.keys(messages).forEach(locale => {
    const matched = locale.match(/[a-z]+/);
    if (matched && matched.length > 0) {
      const fallback_locale = messages[matched[0]];
      messages[locale] = Object.assign({}, fallback_locale, messages[locale]);
    }
  });

  return messages;
}

function getBrowserLocale() {
  let locale =
    navigator.languages[0] ||
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage;

  locale = locale.replace("-", "_");

  switch (locale) {
    case "nl":
      locale = "nl_NL";
      break;
    case "en":
      locale = "en_GB";
      break;
    case "be":
      locale = "fr_BE";
      break;
  }

  return locale;
}

// TODO: Remove this after release of i18n changes
// Custom Formatter implementation
// class CustomFormatter {
//   constructor () {
// }

//
// interpolate
//
// @param {string} message
//   string of list or named format.
//   e.g.
//   - named formatting: 'Hi {name}'
//   - list formatting: 'Hi {0}'
//
// @param {Object | Array} values
//   values of `message` interpolation.
//   passed values with `$t`, `$tc` and `i18n` functional component.
//   e.g.
//   - $t('hello', { name: 'kazupon' }) -> passed values: Object `{ name: 'kazupon' }`
//   - $t('hello', ['kazupon']) -> passed values: Array `['kazupon']`
//   - `i18n` functional component (component interpolation)
//     <i18n path="hello">
//       <p>kazupon</p>
//       <p>how are you?</p>
//     </i18n>
//     -> passed values: Array (included VNode):
//        `[VNode{ tag: 'p', text: 'kazupon', ...}, VNode{ tag: 'p', text: 'how are you?', ...}]`
//
// @return {Array<any>}
//   interpolated values. you need to return the following:
//   - array of string, when is using `$t` or `$tc`.
//   - array included VNode object, when is using `i18n` functional component.
//
// interpolate () {
// implement interpolation logic here
// ...

// return the interpolated array
// return ['resolved <span style="color: red;"><b>message</b></span> string']
// }
// }

const i18n = new VueI18n({
  locale: getBrowserLocale(),
  fallbackLocale: "en_US",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  warnHtmlInMessage: "off"
  // formatter: new CustomFormatter()
});
i18n.browserLocale = getBrowserLocale();

export default i18n;
