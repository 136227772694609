import Vue from "vue";
import firebase from "firebase";
import store from "./store";
import router from "./router";

const config = {
  apiKey:
    process.env.VUE_APP_FIREBASE_API_KEY ||
    "AIzaSyAfOkZAmtcX8QKjOlkESe_Tv8F-wkOienc",
  authDomain:
    process.env.VUE_APP_FIREBASE_AUTH_DOMAIN ||
    "harmonyservicecenter.firebaseapp.com"
};

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        store.dispatch("application/login", token);
        router.push("/").catch(() => {});
      });
  } else {
    console.log("user logged out");
  }
});

Vue.prototype.$firebase = firebase;
