export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET_ALL = (state, data) => {
  state.items = data;
};

export const SET_CATEGORIES = (state, data) => {
  state.categories = data;
};

export const SET_MANUFACTURERS = (state, data) => {
  state.manufacturers = data;
};

export const SET_ITEMS = (state, data) => {
  state.items = data;
};

export const SET_TEMPLATES = (state, data) => {
  state.templates = data;
};

export const RESET = state => {
  state.initialized = false;
  state.categories = [];
  state.manufacturers = [];
};
