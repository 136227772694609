export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET_ALL = (state, data) => {
  state.items = data;
};

export const SET_ALL_LINKS = (state, data) => {
  state.links = data;
};

export const SET = (state, data) => {
  state.item = data;
};

export const RESET = state => {
  state.initialized = false;
  state.items = [];
  state.item = {};
  state.links = [];
};
