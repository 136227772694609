import i18n from "../../plugins/i18n";

export const isInitialized = state => state.initialized;

export const get = state => state.item;

export const getMutation = state => state.mutation;

export const get_ibans = state => state.ibans;

export const getLocale = state => (state.item ? state.item.locale_code : null);

export const getTitle = state =>
  state.item && state.item.gender
    ? i18n.t(`layout.common.titles.${state.item.gender.toLowerCase()}`)
    : i18n.t(`layout.common.titles.neutral`);

export const getSalutation = state =>
  state.item ? `${state.item.middle_name} ${state.item.surname}` : "";
