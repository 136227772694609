<template>
  <h4 :class="['typography-h4']">
    <slot></slot>
  </h4>
</template>

<script>
export default {
  name: "components.typography.h4"
};
</script>
