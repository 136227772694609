export const isInitialized = state => state.initialized;

export const getAll = state => state.items;

export const getAllActive = state =>
  state.items.filter(s => s.status === "Open");

export const getAllAccepted = state =>
  state.items.filter(s => s.status === "Geaccepteerd");

export const getAllDenied = state =>
  state.items.filter(s => s.status === "Afgewezen");

export const getAllOnHold = state =>
  state.items.filter(s => s.status === "On Hold");

export const getAllCancelled = state =>
  state.items.filter(s => s.status === "Afgemeld");

export const get = state => state.item;

export const getClaim = state => state.claim;

export const getStore = state => state.store;

export const getStores = state => state.stores;

export const getAllTemplates = state => state.templates;
