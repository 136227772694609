export const INITIALIZED = state => {
  state.initialized = true;
};

export const SET = (state, data) => {
  state.item = data;
};

export const SET_MUTATION = (state, data) => {
  state.mutation = data;
};

export const SET_IBANS = (state, data) => {
  state.ibans = data;
};

export const RESET = state => {
  state.initialized = false;
  state.item = {};
  state.mutation = {};
};
