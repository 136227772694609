<template>
  <div class="flex w-full">
    <date-range-picker
      v-if="type === 'date'"
      :singleDatePicker="true"
      :ranges="false"
      :minDate="minDate"
      :maxDate="maxDate"
      opens="right"
      :locale-data="localeData"
      v-model="date"
      v-bind="this.$attrs"
      control-container-class="border-0 w-full px-4 py-3.5"
      class="border rounded border-black w-full "
    >
      <template v-slot:input="picker" style="min-width: 350px;" class="">
        <span class="">
          {{
            picker.startDate
              ? format_date(picker.startDate, "DD-MM-YYYY")
              : $t("form.report_date.placeholder")
          }}
        </span>
      </template>
    </date-range-picker>
    <textarea
      v-else-if="type === 'textarea'"
      :class="[
        'border border-brand-gray-500 rounded w-full',
        'py-2 px-3',
        'form-input-default',
        'typography-input-text',
        isDisabled ? 'form-input-default-disabled' : '',
        hasError ? 'form-input-default-error' : ''
      ]"
      v-model="filter"
      v-bind="this.$attrs"
    >
    </textarea>
    <!--    <b-form-textarea></b-form-textarea>-->
    <input
      v-else-if="type === 'file'"
      type="file"
      accept="image/jpeg, image/png, image/jpg"
      :placeholder="$t('form.file.placeholder')"
    />
    <!--      v-model="filter"-->
    <!--      :state="Boolean(filter)"-->
    <!--      :drop-placeholder="$t('form.file.drop_placeholder')"-->
    <!--    <b-form-file></b-form-file>-->

    <input
      v-else
      class="font-serif w-full rounded border px-4 py-3.5 form-input text-brand-gray-500 focus:ring-brand-purple-500 focus:ring-opacity-50 placeholder:text-brand-gray-400 text-base"
      :class="[
        isDisabled ? 'disabled bg-brand-gray-200' : '',
        hasError ? 'border-brand-red-500' : ' border-brand-gray-500'
      ]"
      v-model="filter"
      v-bind="this.$attrs"
    />
    <slot></slot>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import format from "../../../../mixins/format";

export default {
  name: "components.form.input.default",
  components: { DateRangePicker },
  mixin: [format],
  data: () => ({
    date: {
      startDate: "",
      endDate: ""
    },
    filter: ""
  }),
  props: {
    value: [Number, String],
    minDate: [String, Date],
    maxDate: [String, Date]
  },
  computed: {
    isDisabled() {
      return "disabled" in this.$attrs && this.$attrs.disabled === true;
    },
    hasError() {
      return "state" in this.$attrs && !this.$attrs["state"];
    },
    type() {
      return "field_type" in this.$attrs ? this.$attrs.field_type : "";
    },
    localeData() {
      return this.$t("form.report_date.locale_data");
    }
  },
  watch: {
    value() {
      this.filter = this.value;
    },
    filter() {
      this.$emit("input", this.filter);
    },
    date() {
      this.$emit("input", this.format_date(this.date.startDate, "YYYY-MM-DD"));
    }
  },
  created() {
    this.filter = this.value;
  }
};
</script>
