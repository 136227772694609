<template>
  <h2 :class="['typography-h2']">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: "components.typography.h2"
};
</script>
