import Vue from "vue";
import Vuex from "vuex";
import application from "./application";
import user from "./user";
import customer from "./customer";
import contracts from "./contracts";
import claims from "./claims";
import faq from "./faq";
import contact from "./contact";
import payments from "./payments";
import insurance from "./insurance";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    user,
    customer,
    contracts,
    claims,
    faq,
    contact,
    payments,
    insurance
  }
});
