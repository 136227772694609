<template>
  <div>
    <n-form-input-default
      :placeholder="this.placeholder"
      :type="showPassword ? 'text' : 'password'"
      v-model="password"
      class="relative"
    >
      <span
        class="absolute right-5 bottom-[35%] cursor-pointer"
        @click="togglePasswordVisibility"
      >
        <n-icon
          small
          :name="!this.showPassword ? 'Eye-open' : 'Eye-closed'"
          class="show-password-icon"
        ></n-icon>
      </span>
    </n-form-input-default>
  </div>
</template>
<script>
export default {
  name: "components.form.input.password",
  props: {
    value: String,
    placeholder: String
  },
  data() {
    return {
      password: this.value,
      showPassword: false
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    password(value) {
      this.$emit("input", value);
    }
  }
};
</script>
