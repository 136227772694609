<template>
  <div
    v-if="visible"
    :class="
      `flex justify-between items-center rounded p-3 bg-opacity-50 border font-serif
      ${
        variant === 'primary'
          ? 'bg-brand-purple-100 border-brand-purple-500 text-brand-purple-500'
          : ''
      }
      ${variant === 'danger' ? 'bg-red-100 border-red-500 text-red-500' : ''}
      ${
        variant === 'success'
          ? 'bg-green-100 border-green-500 text-green-500'
          : ''
      }
      `
    "
  >
    <div>
      <slot />
    </div>
    <button
      @click="closeAlert"
      class="ml-2 bg-transparent border-none cursor-pointer text-lg font-bold"
    >
      &times;
    </button>
  </div>
</template>

<script>
export default {
  name: "components.theme.alert",
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      visible: true
    };
  },
  methods: {
    closeAlert() {
      this.visible = false;
    }
  }
};
</script>
