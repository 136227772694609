export default [
  {
    path: "/auth",
    component: () => import("../layouts/auth"),
    children: [
      {
        path: "",
        redirect: { name: "auth.login" }
      },
      {
        path: "login",
        name: "auth.login",
        component: () => import("../pages/auth/login")
      },
      {
        path: "register",
        name: "auth.register",
        component: () => import("../pages/auth/register")
      },
      {
        path: "forgot_password",
        name: "auth.forgot_password",
        component: () => import("../pages/auth/forgot_password")
      },
      {
        path: "reset_password",
        name: "auth.reset_password",
        component: () => import("../pages/auth/reset_password")
      },
      {
        path: "change_email",
        name: "auth.change_email",
        component: () => import("../pages/auth/change_email")
      }
    ],
    meta: {
      auth: false
    }
  },
  {
    path: "/init",
    component: () => import("../layouts/bare"),
    children: [
      {
        path: "",
        name: "init",
        props: true,
        component: () => import("../pages/init")
      }
    ],
    meta: {
      auth: false
    }
  },
  {
    path: "/processing",
    component: () => import("../layouts/bare"),
    children: [
      {
        path: "",
        name: "processing",
        component: () => import("../pages/claims/loading")
      }
    ]
  },
  {
    path: "/maintenance",
    component: () => import("../layouts/auth"),
    children: [
      {
        path: "",
        name: "maintenance",
        component: () => import("../pages/error/maintenance")
      }
    ]
  },
  {
    path: "/details",
    component: () => import("../layouts/switcher"),
    children: [
      {
        path: "personal",
        name: "details.personal",
        component: () => import("../pages/details/personal")
      },
      {
        path: "payment",
        name: "details.payment",
        component: () => import("../pages/details/payment")
      }
    ],
    meta: {
      auth: true
    }
  },
  {
    path: "/report",
    component: () => import("../layouts/switcher"),
    children: [
      {
        path: "theft",
        name: "report.theft",
        component: () => import("../pages/report/theft")
      },
      {
        path: "damage",
        name: "report.damage",
        component: () => import("../pages/report/damage")
      }
    ],
    meta: {
      auth: true
    }
  },
  {
    path: "/insurances/new",
    component: () => import("../layouts/switcher"),
    children: [
      {
        path: "",
        name: "insurances.new",
        component: () => import("../pages/insurances/new")
      }
    ]
  },
  {
    path: "/",
    component: () => import("../layouts/switcher"),
    children: [
      {
        path: "",
        name: "overview",
        component: () => import("../pages/index")
      },
      {
        path: "insurances",
        name: "insurances.index",
        component: () => import("../pages/insurances/index")
      },
      {
        path: "claims",
        name: "claims.index",
        component: () => import("../pages/claims/index")
      },
      {
        path: "payments",
        name: "payments.index",
        component: () => import("../pages/payments/index")
      },
      {
        path: "customer_service",
        name: "service.index",
        component: () => import("../pages/service/index")
      }
    ],
    meta: {
      auth: true
    }
  },
  {
    path: "/support",
    component: () => import("../layouts/auth"),
    children: [
      {
        path: "upload",
        name: "support.upload",
        component: () => import("../pages/support/image")
      }
    ]
  },
  {
    // Always leave this as last one
    path: "*",
    component: () => import("../layouts/auth"),
    children: [
      {
        path: "*",
        name: "error.404",
        component: () => import("../pages/error/404")
      }
    ],
    meta: {
      auth: false
    }
  }
];
