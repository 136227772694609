<template>
  <button
    :class="[
      'text-sm font-semibold py-2 px-4 rounded-3xl transition transform hover:-translate-y-0.5',
      isSecondary
        ? 'bg-brand-purple-100 bg-opacity-50 text-brand-purple-500 border border-brand-purple-500'
        : 'bg-brand-yellow-500 text-brand-gray-500',
      isCancel
        ? 'bg-brand-red-100 bg-opacity-50 text-brand-red-500 border border-brand-red-500'
        : '',
      isDisabled ? 'bg-brand-gray-300 text-brand-gray-400 bg-opacity-40' : '',
      isFullWidth ? 'w-full' : ''
    ]"
    :disabled="isDisabled"
    v-bind="this.$attrs"
  >
    <slot></slot>

    <n-icon
      v-if="this.$attrs.icon"
      :name="this.$attrs.icon"
      :white="!isSecondary"
      style="margin-left:12px;margin-bottom: 3px;"
    ></n-icon>
  </button>
</template>

<script>
export default {
  name: "components.button.regular",
  computed: {
    isCancel() {
      return "cancel" in this.$attrs;
    },
    isSecondary() {
      return "secondary" in this.$attrs;
    },
    isFullWidth() {
      return "full-width" in this.$attrs;
    },
    isDisabled() {
      return "disabled" in this.$attrs && this.$attrs.disabled === true;
    }
  }
};
</script>
