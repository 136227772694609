export const isInitialized = state => state.initialized;

export const getCategories = state => state.categories;

export const getManufacturers = state =>
  state.manufacturers.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

export const getItems = state => state.items;

export const getTemplates = state => state.templates;
