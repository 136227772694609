<template>
  <p :class="[isSmall ? 'typography-p-small' : 'typography-p-regular']">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "components.typography.p",
  computed: {
    isSmall() {
      return "small" in this.$attrs;
    }
  }
};
</script>
