export const isInitialized = state => state.initialized;

export const getAll = state => state.items;

export const getAllActive = state =>
  state.items.filter(s => s.status === "Active");

export const getAllExpired = state =>
  state.items.filter(s => s.status !== "Active");

export const get = state => state.item;

export const getClosingCodes = state => state.closing_codes;

export const getClosingDate = state => state.closing_date;

export const getClosingReasons = state => state.closing_reasons;
